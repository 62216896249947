import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import React, { useState } from "react";
import Modal from "react-modal";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const customStylesBox = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'transparent',
    border: '0',
    borderRadius: '0',
    padding: '0',
    width: '100%',
    height: '100%',
    zindex: '999',
  },
};

const SliderWithModalContent = ({ module }) => {
  const [modalIsOpenBox, setIsOpenBox] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [curentSlide, setCurentSlide] = useState('');

  const openModalBox = (content) => {
    setModalContent(content);
    document.body.classList.add("video-modal-open");
    setIsOpenBox(true);
  }
  const closeModalBox = () => {
    document.body.classList.remove("video-modal-open");
    setIsOpenBox(false);
  }

  return (
    <>
      <section className={`destinations-slider destination-slider--mtt trip-ds bg-pattern lg:py-120 py-30${module?.extraClass ? " " + module.extraClass : ""}`}>
        <div className='container-fluid pr-0'>
          <div className='section-title fade-ani'>
            {
              module?.heading && (
                <div className='title-black fade-ani'>
                  <h3>{module.heading}</h3>
                </div>
              )
            }
            {
              module?.description && (
                <div className='content '>
                  {parse(module.description)}
                </div>
              )
            }
          </div>
          <div id="destinations-slider-row" className='destinations-slider-row destinations-drag-section lg:mt-50 mt-25'>
            <Swiper
              spaceBetween={20}
              loop={false}
              slidesPerView={2.7}
              centeredSlides={false}
              allowTouchMove={true}
              scrollbar={{
                hide: false
              }}
              speed={1000}
              onSlideChange={(swiperCore) => {
                const { activeIndex } = swiperCore;
                setCurentSlide(activeIndex)
              }}
              navigation={false}
              modules={[Autoplay, Pagination, Scrollbar]}
              className=""
              breakpoints={{
                100: {
                  slidesPerView: 1.5,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2.7,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 2.7,
                  spaceBetween: 20,
                },
              }}
            >
              {
                module.slides.map((item, index) => (
                  <SwiperSlide key={index.toString()}>
                    {/* <div onClick={() => openModalBox(item?.modalContent)} className='ds-slider-content relative'></div> */}
                    <div className='ds-slider-content relative'>
                      <GatsbyImage 
                        image={getImage(item?.image)}
                        alt={item?.image?.altText}
                        className="fade-ani"
                      />
                      <div className='ds-content absolute bottom-50 mdscreen:bottom-5 w-full text-white z-9 lg:px-30 px-15 hover:cursor-default'>
                        {item?.heading && <h3 className='lg:text-35 text-white uppercase lgscreen:w-full fade-ani'>{item.heading}</h3>}
                        <div className="">{item?.description && parse(item.description)}</div>
                        {/* <div className='btn-custom flex flex-wrap items-start justify-start space-x-8 mt-30 '>
                          <button className='button btn-white-border'>Read More</button>
                        </div> */}
                      </div>
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>

          {/* <div className="destinations-button-drag" id="destinations-button-drag" > <span className="button-text">Drag</span></div> */}
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpenBox}
        onRequestClose={closeModalBox}
        style={customStylesBox}
      >
        <div className="zigzag-modal-popup bg-pattern2">
          <button onClick={closeModalBox} className="absolute right-30 top-30 w-50 h-50 bg-white rounded-999 inline-block z-99">
            <img src="/images/modal-close.png" loading="lazy" className='m-auto w-20 h-20' alt="Close" />
          </button>
          <div className='flex flex-wrap items-center'>
            <div className={`lg:w-5/12 w-full`}>
              <div className='img-slider'>
                <Swiper
                  spaceBetween={0}
                  loop={false}
                  slidesPerView={1}
                  allowTouchMove={true}
                  grabCursor={true}
                  speed={1000}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="slider mdscreen:h-[90vw]"
                >
                  {
                    modalContent?.images?.map((image, index) => (
                      <SwiperSlide key={index.toString()}>
                        <GatsbyImage
                          image={getImage(image)}
                          alt={image?.altText || ""}
                        />
                      </SwiperSlide>
                    ))
                  }
                </Swiper>
              </div>
            </div>
            <div className='lg:w-7/12 w-full'>
              <div className='zigzag-modal-popup-content lg:px-100 px-30 lg:pt-0 py-30 fade-ani'>
                {modalContent?.preHeading && <span className='text-red-300 uppercase text-15 mb-15 inline-block w-full'>{modalContent?.preHeading}</span>}
                {
                  modalContent?.heading && (
                    <div className='title-black '>
                      <h3>{modalContent?.heading}</h3>
                    </div>
                  )
                }
                {
                  modalContent?.description && (
                    <div className='content global-list '>
                      {parse(modalContent.description)}
                    </div>
                  )
                }
                {
                  modalContent?.button && (
                    <div className="btn-custom flex flex-wrap items-center space-x-8 mt-30 relative z-9 ">
                      <Link to={modalContent.button?.url} target={modalContent.button?.target} className="button header-btn-red" >
                        <span>{modalContent.button?.title}</span>
                      </Link>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SliderWithModalContent;
export const TeamSliderWithModalContentFragment = graphql`
  fragment TeamSliderWithModalContentFragment on WpTeam_Teamcontent_TeamContent_SliderWithModalContent {
    id
    extraClass
    hideSection
    heading
    description
    slides {
      heading
      description
      image {
        altText
        gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1024)
      }
      modalContent {
        preHeading
        heading
        description
        images {
          altText
          gatsbyImage(formats: WEBP, placeholder: BLURRED, width: 1920)
        }
      }
    }
  }
`;