import ImageOpt from './ImageOpt';
import { motion, useInView } from 'framer-motion';
import { Link, graphql } from 'gatsby';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';

const ImageWithContent = ({ module }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const [hasPlayed, setHasPlayed] = useState(false);

  const container = {
    hidden: { opacity: 1 },
    show: {
      opacity: 1,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        delayChildren: 0.5,
        staggerChildren: 0.2,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: 'easeOut',
      },
    },
  };

  useEffect(() => {
    if (isInView && !hasPlayed) {
      setHasPlayed(true);
    }
  }, [isInView, hasPlayed, setHasPlayed]);
  return (
    <section
      className={`zigzag lg:py-60 py-30 mt-0${
        module?.extraClass ? ' ' + module.extraClass : ''
      }`}
    >
      <div className="container-fluid">
        <div className="flex flex-wrap items-center">
          <div
            className={`lg:w-6/12 h-[37vw] lgscreen:h-[75vw] w-full${
              module?.imagePosition === 'right' ? ' lg:order-2' : ''
            }`}
          >
            {module?.image && (
              <ImageOpt
                imgData={module.image.mediaItemUrl}
                width={650}
                height={533}
                imgAlt={module.image?.altText}
                imgClass="img object-cover w-full"
              />
            )}
          </div>
          <div className="lg:w-6/12 w-full">
            <motion.div
              ref={ref}
              initial="hidden"
              animate={isInView || hasPlayed ? 'show' : 'hidden'}
              variants={container}
              className="zigzag-content lg:pt-0 pt-30 fade-ani"
            >
              {module?.preHeading && (
                <motion.span
                  variants={item}
                  className="text-red-300 uppercase text-15 mb-5 inline-block fade-ani"
                >
                  {module.preHeading}
                </motion.span>
              )}
              {module?.heading && (
                <motion.div variants={item} className="title-black ">
                  <h3>{module.heading}</h3>
                </motion.div>
              )}
              {module?.description && (
                <motion.div variants={item} className="content global-list ">
                  {parse(module.description)}
                </motion.div>
              )}
              {module?.link && (
                <motion.div
                  variants={item}
                  className="btn-custom flex flex-wrap items-start justify-start space-x-8 mt-30 relative z-9"
                >
                  <Link
                    to={module.link?.url}
                    className={
                      module?.linkStyle === 'link'
                        ? 'button btn-red-border'
                        : 'button header-btn-red cursor-pointer'
                    }
                  >
                    {module.link?.title}
                  </Link>
                </motion.div>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImageWithContent;

export const ImageWithContentFragment = graphql`
  fragment ImageWithContentFragment on WpPage_Pagecontent_PageContent_ImageWithContent {
    id
    extraClass
    hideSection
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    linkStyle
    link {
      target
      title
      url
    }
  }
`;

export const NavigationContent_ImageWithContentFragment = graphql`
  fragment NavigationContent_ImageWithContentFragment on WpDestination_Destinationcontent_DestinationContent_NavigationContent_tabs_DestinationContent_ImageWithContent {
    id
    extraClass
    hideSection
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    link {
      target
      title
      url
    }
  }
`;
export const TeamSingleImageWithContentFragment = graphql`
  fragment TeamSingleImageWithContentFragment on WpTeam_Teamcontent_TeamContent_ImageWithContent {
    id
    extraClass
    hideSection
    preHeading
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    linkStyle
    link {
      target
      title
      url
    }
  }
`;

export const MonthlyEventImageWithContentFragment = graphql`
  fragment MonthlyEventImageWithContentFragment on WpPage_Pagecontent_PageContent_MonthlyEvents_months_events_Content_ImageWithContent {
    id
    extraClass
    hideSection
    preHeading
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    linkStyle
    link {
      target
      title
      url
    }
  }
`;

export const TravelStyleImageWithContentFragment = graphql`
  fragment TravelStyleImageWithContentFragment on WpTravel_Travelstylecontent_TravelStyle_ImageWithContent {
    id
    extraClass
    hideSection
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    linkStyle
    link {
      target
      title
      url
    }
  }
`;
export const ExperienceImageWithContentFragment = graphql`
  fragment ExperienceImageWithContentFragment on WpExperience_Experiencecontent_Experience_ImageWithContent {
    id
    extraClass
    hideSection
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    linkStyle
    link {
      target
      title
      url
    }
  }
`;
export const DestinationImageWithContentFragment = graphql`
  fragment DestinationImageWithContentFragment on WpDestination_Destinationcontent_DestinationContent_ImageWithContent {
    id
    extraClass
    hideSection
    heading
    description
    imagePosition
    image {
      altText
      mediaItemUrl
      gatsbyImage(
        formats: [AUTO, WEBP, AVIF]
        placeholder: BLURRED
        width: 1024
      )
    }
    linkStyle
    link {
      target
      title
      url
    }
  }
`;
