import { graphql } from "gatsby";
import React from "react";

import Banner from "../components/Banner"
import RelatedTripInspiration from "../components/RelatedTripInspiration"
import SimpleFullImageWithContent from "../components/SimpleFullImageWithContent"
import ImageWithContent from "../components/ImageWithContent"
import ClientTestimonials from "../components/ClientTestimonials"
import RelatedBlog from "../components/RelatedBlog"
import Faq from "../components/Faq"
import SliderWithModalContent from "../components/SliderWithModalContent"

const Team = ({ modules, location, title, teamMemberId }) => {
  const components = {
    Banner,
    RelatedTripInspiration,
    SimpleFullImageWithContent,
    ImageWithContent,
    ClientTestimonials,
    RelatedBlog,
    Faq,
    SliderWithModalContent
  }

  return (
    <>
      {modules
        ? modules.filter((module) => !module?.hideSection || module?.hideSection === "no").map((module, i) => {
            const moduleName = module.__typename.replace(
              "WpTeam_Teamcontent_TeamContent_",
              ""
            );
            return (
              components[moduleName] &&
              React.createElement(components[moduleName], {
                key: i,
                module,
                location,
                title,
                teamMemberId
              })
            );
          })
        : null}
    </>
  );
}

export default Team;
export const TeamModulesFragment = graphql`
  fragment TeamModulesFragment on WpTeam {
    teamContent {
      teamContent {
        __typename
        ...TeamBannerFragment
        ...TeamSingleRelatedTripInspirationFragment
        ...TeamSingleSimpleFullImageWithContentFragment
        ...TeamSingleImageWithContentFragment
        ...ClientTestimonialsFragment
        ...TeamSingleRelatedBlogFragment
        ...FaqFragment
        ...TeamSliderWithModalContentFragment
      }
    }
  }
`;