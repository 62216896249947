import { graphql } from "gatsby";
import React, { useEffect } from "react";
import PageLayout from "../components/Layout/Page";
import Seo from "../components/Seo";
import Team from "../modules/Team";

const TeamTemplate = (props) => {
  const modules = props?.data?.team?.teamContent?.teamContent || [];
  const title = props?.data?.team?.title || "";
  const teamMemberId = props?.data?.team?.id || "";
  
  useEffect(() => {
    document.body.classList.remove("nav-menu-open");
    document.body.classList.remove("header-sticky");
    document.body.classList.remove("video-modal-open");
    document.body.classList.remove("blog-single-custom");

  }, []);

  return (
    <PageLayout>
      <Seo seo={props.data.team.seo} />
      <Team modules={modules} location={props.location} title={title} teamMemberId={teamMemberId} />
    </PageLayout>
  )
}

export default TeamTemplate;
export const TeamById = graphql`
  query teamById ($id: String) {
    team: wpTeam(id: { eq: $id }) {
      id
      title
      uri
      ...seoTeamFragment
      ...TeamModulesFragment
    }
  }
`;