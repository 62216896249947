import { graphql } from "gatsby";
import parse from "html-react-parser";
import React from "react";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ClientTestimonials = ({ module, title, teamMemberId }) => {  
  return (
    <section className={`client-testimonials lg:py-100 py-30${module?.extraClass ? " " + module.extraClass : ""}`}>
      <div className='w-[735px] m-auto lgscreen:w-full lgscreen:px-30 text-center fade-ani'>
        {module?.heading &&
          <span className='uppercase text-16 text-black-500 font-heading'>{module.heading}</span>
        }
        <div className='client-testimonials-slider mt-50'>
          <Swiper
            spaceBetween={0}
            loop={false}
            slidesPerView={1}
            speed={1000}
            grabCursor={true}
            pagination={{
              clickable: true,
            }}
            navigation={false}
            modules={[Autoplay]}
            className="mySwiper"
          >
            {module?.clientTestimonials?.length > 0 && module.clientTestimonials.map((item, index) => (
              <SwiperSlide key={index.toString()}>
                <div className='ct-content'>
                  {item?.review &&
                    <h4 className="">
                      {parse(item.review)}
                    </h4>
                  }
                  {item?.detail &&
                    <span className='uppercase text-14 text-black-500 mt-30 inline-block '>{item.detail}</span>
                  }
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {title && 
            <div className="btn-custom mt-30 relative z-9">
              <button className="button header-btn-red" >{`Plan Your Trip With ${title}`}</button>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default ClientTestimonials;
export const ClientTestimonialsFragment = graphql`
  fragment ClientTestimonialsFragment on WpTeam_Teamcontent_TeamContent_ClientTestimonials {
    id
    hideSection
    extraClass
    heading
    clientTestimonials {
      detail
      review
    }
  }
`;  